import {
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { IdInput, StaleTimes, getParamsInput } from 'api-hooks/common';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import {
  ApiQueryFunction,
  InfiniteApiQueryFunction,
} from 'common/api/fetch-types';
import { ApiError, ApiResult, ExtendedApiResult } from 'common/api/model';
import { maybeElement } from 'common/utils/iterable';

import {
  CreditDebitCardModel,
  PaymentMethodModel,
  getPaymentMethodsInput,
} from './model';

export const paymentMethodKey = {
  listKey: 'getPaymentMethods',
  list(input?: getPaymentMethodsInput) {
    return [paymentMethodKey.listKey, input?.params];
  },
};

export const creditDebitCardsKey = {
  listKey: 'getCreditDebitCards',
  detailKey: 'getCreditDebitCard',
  list(input?: getParamsInput) {
    return [
      creditDebitCardsKey.listKey,
      ...maybeElement(!!input, input?.params),
    ];
  },
  detail(input?: IdInput) {
    return [creditDebitCardsKey.detailKey, ...maybeElement(!!input, input?.id)];
  },
};

export function useGetPaymentMethods(
  input?: getPaymentMethodsInput,
  options?: UseQueryOptions<ApiResult<PaymentMethodModel[]>, ApiError>,
): UseQueryResult<ApiResult<PaymentMethodModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      paymentMethodKey.list(input),
      () =>
        QueryFetchFunction({ url: 'payment-methods', params: input?.params }),
      options,
    ),
    PaymentMethodModel,
  );
}

const CARD_ENDPOINT = 'credit-debit-cards';
export const useGetCreditDebitCards: InfiniteApiQueryFunction<
  getParamsInput,
  ExtendedApiResult<CreditDebitCardModel[]>
> = (input, options) => {
  return QueryTransformer(
    useInfiniteQuery(
      creditDebitCardsKey.list(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: CARD_ENDPOINT,
          params: {
            ...input?.params,
            page: pageParam,
          },
        }),
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    CreditDebitCardModel,
  );
};
export const useGetCreditDebitCard: ApiQueryFunction<
  IdInput,
  ApiResult<CreditDebitCardModel>
> = (input, options) => {
  return QueryTransformer(
    useQuery(
      creditDebitCardsKey.detail(input),
      () => QueryFetchFunction({ url: `${CARD_ENDPOINT}/${input.id}` }),
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    CreditDebitCardModel,
  );
};
