import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import { ApiError, ApiResult } from 'common/api/model';

import {
  CartItemDetailModel,
  CartModel,
  getCartItemDetailInput,
} from './model';

export const cartKey = {
  getCartKey: 'getCart',
  getCartItemDetailKey: 'getCartItemDetailKey',
  getCart() {
    return [cartKey.getCartKey];
  },
  getCartItemDetail(input?: getCartItemDetailInput) {
    return [cartKey.getCartItemDetailKey, input?.cartItemId];
  },
};

export function useGetCart(
  options?: UseQueryOptions<ApiResult<CartModel>, ApiError>,
): UseQueryResult<ApiResult<CartModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      cartKey.getCart(),
      () => QueryFetchFunction({ url: 'carts' }),
      options,
    ),
    CartModel,
  );
}

export function useGetCartItemDetail(
  input?: getCartItemDetailInput,
  options?: UseQueryOptions<ApiResult<CartItemDetailModel>, ApiError>,
): UseQueryResult<ApiResult<CartItemDetailModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      cartKey.getCartItemDetail(input),
      () => QueryFetchFunction({ url: `carts/items/${input?.cartItemId}` }),
      options,
    ),
    CartItemDetailModel,
  );
}
