import { Expose, Type } from 'class-transformer';
import { CreditDebitCardStatusEnum } from 'common/constants/enum';

// Model
export class PaymentMethodDetailModel {
  brand: string;
  status: CreditDebitCardStatusEnum;

  @Expose({ name: 'check_digit' })
  checkDigit: string;
}

export class PaymentMethodModel {
  id: string;
  name: string;
  type: string;
  image: string;
  available: boolean;
  threshold: string;

  @Type(() => PaymentMethodDetailModel)
  details: PaymentMethodDetailModel;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'payment_fee' })
  @Type(() => Number)
  paymentFee: number;

  @Expose({ name: 'payment_identifier_id' })
  paymentIdentifierId: string;
}

export class CreditDebitCardModel {
  id: string;
  brand: string;
  status: CreditDebitCardStatusEnum;
  image?: string;

  @Expose({ name: 'bank_name' })
  bankName: string;

  @Expose({ name: 'check_digit' })
  checkDigit: string;

  /** Starts at 1 */
  @Expose({ name: 'expired_month' })
  @Type(() => Number)
  expiredMonth: number;

  @Expose({ name: 'expired_year' })
  @Type(() => Number)
  expiredYear: number;

  get isExpired() {
    return this.status === CreditDebitCardStatusEnum.Expired;
  }
}

// Input
export type getPaymentMethodsInput = {
  params: {
    amount: number;
  };
};

export type CreditDebitCardMutationInput = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  province: string;
  city: string;
  postalCode: string;
  countryCode: string;
  token: string;
  authenticationId: string;
};
