import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { notificationKey } from 'api-hooks/notification/query';
import { saleInvoiceKey } from 'api-hooks/sale-invoice';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiError, MessageResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';

import { AddToCartInput, DeleteCartItemInput, EditCartInput } from './model';
import { cartKey } from './query';

export function useAddToCart(
  options?: UseMutationOptions<MessageResult, ApiError, AddToCartInput>,
): UseMutationResult<MessageResult, ApiError, AddToCartInput> {
  return useMutation<MessageResult, ApiError, AddToCartInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'carts/items',
        method: 'POST',
        body: body.body,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([cartKey.getCartKey]);
        queryClient.removeQueries([saleInvoiceKey.billCartKey]);
        queryClient.invalidateQueries([notificationKey.badgesKey]);
      },
      ...options,
    },
  );
}

export function useEditCart(
  options?: UseMutationOptions<MessageResult, ApiError, EditCartInput>,
): UseMutationResult<MessageResult, ApiError, EditCartInput> {
  return useMutation<MessageResult, ApiError, EditCartInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'carts/edit',
        method: 'PATCH',
        body: body.body,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([cartKey.getCartKey]);
        queryClient.removeQueries([saleInvoiceKey.billCartKey]);
        queryClient.invalidateQueries([notificationKey.badgesKey]);
      },
      ...options,
    },
  );
}

export function useRemoveCart(
  options?: UseMutationOptions<MessageResult, ApiError, DeleteCartItemInput>,
): UseMutationResult<MessageResult, ApiError, DeleteCartItemInput> {
  return useMutation<MessageResult, ApiError, DeleteCartItemInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'carts/remove',
        method: 'PATCH',
        body: body.body,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([cartKey.getCartKey]);
        queryClient.removeQueries([saleInvoiceKey.billCartKey]);
        queryClient.invalidateQueries([notificationKey.badgesKey]);
      },
      ...options,
    },
  );
}
