import { getParamsInput } from 'api-hooks/common';
import { PaymentMethodModel } from 'api-hooks/payment-method';
import { ReferralModel } from 'api-hooks/referral';
import { Expose, Type } from 'class-transformer';
import { ApiResult } from 'common/api/model';
import {
  CheckoutPromoTypeEnum,
  DataPlanTypeEnum,
  PaymentMethodTypeEnum,
  ProductItemGradeEnum,
  ProductScaleEnum,
  SaleInvoicePillStatusEnum,
  SaleInvoiceStatusEnum,
  SaleInvoiceTypeEnum,
  SimStatusEnum,
} from 'common/constants/enum';

// Model
export class SaleInvoiceMetadataModel {
  name: string;
  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'data_type' })
  dataType: DataPlanTypeEnum;

  @Expose({ name: 'total_countries' })
  @Type(() => Number)
  totalCountries: number;

  @Expose({ name: 'quota_in_gb' })
  @Type(() => Number)
  quotaInGb: number;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;
}

export class SaleInvoiceSimModel {
  id: string;
  label: string;
  skin: string;
  iccid: string;
  status: SimStatusEnum;

  @Expose({ name: 'assigned_at' })
  @Type(() => Date)
  assignedAt: Date;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class SaleInvoiceFirstItemModel {
  id: string;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Type(() => Number)
  qty: number;

  @Type(() => Number)
  total: number;

  @Type(() => SaleInvoiceMetadataModel)
  metadata: SaleInvoiceMetadataModel;

  @Type(() => SaleInvoiceSimModel)
  sim: SaleInvoiceSimModel | null;

  @Expose({ name: 'fx_price' })
  @Type(() => Number)
  fxPrice: number;

  @Expose({ name: 'fx_rate' })
  @Type(() => Number)
  fxRate: number;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;
}

export class SaleInvoiceLiteModel {
  id: string;
  number: string;
  status: SaleInvoiceStatusEnum;

  @Expose({ name: 'pill_status' })
  pillStatus: SaleInvoicePillStatusEnum;

  @Type(() => Number)
  total: number;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'first_item' })
  @Type(() => SaleInvoiceFirstItemModel)
  firstItem: SaleInvoiceFirstItemModel;

  @Expose({ name: 'total_items' })
  @Type(() => Number)
  totalItems: number;
}

export class SaleInvoiceItemModel extends SaleInvoiceFirstItemModel {}

export class SaleInvoicePaymentableModel {
  id: string;
  provider: string;

  @Expose({ name: 'invoice_url' })
  invoiceUrl: string;

  @Type(() => Number)
  amount: number;
}

export class SaleInvoiceModel {
  id: string;
  number: string;
  // Disable mutating payment if type is not regular
  type: SaleInvoiceTypeEnum;
  status: SaleInvoiceStatusEnum;

  @Expose({ name: 'pill_status' })
  pillStatus: SaleInvoicePillStatusEnum;

  @Expose({ name: 'payment_method' })
  paymentMethod: string;

  @Type(() => Number)
  subtotal: number;

  @Type(() => Number)
  discount: number;

  @Type(() => Number)
  total: number;

  @Type(() => Number)
  cashback: number | null;

  @Type(() => SaleInvoiceItemModel)
  items: SaleInvoiceItemModel[];

  @Type(() => SaleInvoicePaymentableModel)
  paymentable: SaleInvoicePaymentableModel;

  @Expose({ name: 'service_fee' })
  @Type(() => Number)
  serviceFee: number;

  @Expose({ name: 'payment_fee' })
  @Type(() => Number)
  paymentFee: number;

  @Expose({ name: 'kuro_points' })
  @Type(() => Number)
  kuroPoints: number;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'due_at' })
  @Type(() => Date)
  dueAt: Date;

  @Expose({ name: 'completed_at' })
  @Type(() => Date)
  completedAt: Date;

  @Expose({ name: 'canceled_at' })
  @Type(() => Date)
  canceledAt: Date;

  @Expose({ name: 'expired_at' })
  @Type(() => Date)
  expiredAt: Date;

  @Expose({ name: 'rejected_at' })
  @Type(() => Date)
  rejectedAt: Date;

  @Expose({ name: 'refunded_at' })
  @Type(() => Date)
  refundedAt: Date;
}

export class SaleInvoiceBillItemModel {
  name: string;
  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'data_type' })
  dataType: DataPlanTypeEnum;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Type(() => SaleInvoiceSimModel)
  sim: SaleInvoiceSimModel;

  @Type(() => Number)
  qty: number;

  @Type(() => Number)
  total: number;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'fx_price' })
  @Type(() => Number)
  fxPrice: number;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Expose({ name: 'quota_in_gb' })
  @Type(() => Number)
  quotaInGb: number;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;

  @Expose({ name: 'activation_at' })
  @Type(() => Date)
  activationAt: Date;
}

export class SaleInvoiceAvailablePaymentMethodModel {
  label: string;
  type: PaymentMethodTypeEnum;

  @Type(() => PaymentMethodModel)
  items: PaymentMethodModel[];
}

export class SaleInvoicePromoModel {
  code: string;
  type: CheckoutPromoTypeEnum;

  @Expose({ name: 'is_usable' })
  isUsable: boolean;

  @Expose({ name: 'reward_amount' })
  @Type(() => Number)
  rewardAmount: number;
}

export class BankDetailModel {
  id: string;
  name: string;
  image: string;
}

export class GuideBodyModel {
  title: string;
  body: string[];
}

export class GuideModel {
  title: string;

  @Type(() => GuideBodyModel)
  body: GuideBodyModel[];
}

export class PaymentMethodEwalletModel {
  code: string;
  image: string;
}

export class PaymentableModel {
  id: string;
  provider: 'xendit';
  image: string;

  @Expose({ name: 'qris_string' })
  qrisString: string;

  @Type(() => GuideModel)
  guides: GuideModel[];

  @Expose({ name: 'desktop_web_checkout_url' })
  desktopWebCheckoutUrl: string;

  @Expose({ name: 'mobile_web_checkout_url' })
  mobileWebCheckoutUrl: string;

  @Expose({ name: 'mobile_deeplink_checkout_url' })
  mobileDeeplinkCheckoutUrl: string;

  @Expose({ name: 'qr_checkout_string' })
  qrCheckoutString: string;

  @Expose({ name: 'due_at' })
  @Type(() => Date)
  dueAt: Date;

  @Expose({ name: 'va_number' })
  vaNumber: string;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'bank_detail' })
  @Type(() => BankDetailModel)
  bankDetail: BankDetailModel;

  @Expose({ name: 'payment_method' })
  @Type(() => PaymentMethodEwalletModel)
  paymentMethod: PaymentMethodEwalletModel;

  @Expose({ name: 'is_available' })
  isAvailable: boolean;
}

export class SaleInvoiceShowPendingPaymentModel {
  label: string;
  type: PaymentMethodTypeEnum;

  @Expose({ name: 'due_at' })
  @Type(() => Date)
  dueAt: Date;

  @Type(() => PaymentableModel)
  paymentable: PaymentableModel;

  @Expose({ name: 'payment_status' })
  paymentStatus: boolean;
}

export class SaleInvoiceBillModel {
  @Expose({ name: 'can_checkout' })
  canCheckout: boolean;

  @Type(() => SaleInvoicePromoModel)
  promo: SaleInvoicePromoModel | null;

  @Type(() => SaleInvoiceBillItemModel)
  items: SaleInvoiceBillItemModel[];

  @Type(() => Number)
  price: number;

  @Expose({ name: 'service_fee' })
  @Type(() => Number)
  serviceFee: number;

  @Expose({ name: 'total_payment' })
  @Type(() => Number)
  totalPayment: number;
}

export class SaleInvoiceCheckoutModel {
  @Expose({ name: 'sale_invoice_id' })
  saleInvoiceId: string;
}

export class SaleInvoicePreparePaymentModel {
  price: string;
  subtotal: string;

  @Expose({ name: 'kuro_points_balance' })
  kuroPointsBalance: string;

  @Expose({ name: 'usable_kuro_points' })
  usableKuroPoints: string;

  @Expose({ name: 'service_fee' })
  serviceFee: string;

  @Expose({ name: 'payment_method' })
  @Type(() => PaymentMethodModel)
  paymentMethod: PaymentMethodModel;

  @Expose({ name: 'kuro_points' })
  @Type(() => Number)
  kuroPoints: number;

  @Expose({ name: 'total_payment' })
  @Type(() => Number)
  totalPayment: number;

  @Expose({ name: 'is_threshold' })
  isThreshold: boolean;

  @Expose({ name: 'can_settle' })
  canSettle: boolean;

  @Type(() => Number)
  threshold: number;

  @Expose({ name: 'available_payment_methods' })
  @Type(() => SaleInvoiceAvailablePaymentMethodModel)
  availablePaymentMethods: SaleInvoiceAvailablePaymentMethodModel[];

  // Analytics Requirement
  @Type(() => ReferralModel)
  referral?: ReferralModel;

  @Type(() => SaleInvoiceFirstItemModel)
  items?: SaleInvoiceFirstItemModel[];
  // End Analytics Requirement
}

export class SaleInvoiceSettlePaymentModel {
  settled: boolean;
  type: PaymentMethodTypeEnum;

  @Expose({ name: 'sale_invoice_id' })
  saleInvoiceId: string;
}

export class SaleInvoiceCheckPaymentStatusModel {
  @Expose({ name: 'payment_status' })
  paymentStatus: boolean;
}

export class SaleInvoiceShowStatusModel {
  status: SaleInvoiceStatusEnum;

  @Expose({ name: 'pill_status' })
  pillStatus: SaleInvoicePillStatusEnum;
}

export type PaymentFlowApiResult<T> = ApiResult<T> & {
  redirect?: boolean;
  error?: boolean;
};

// Input
export type getSaleInvoicesInput = getParamsInput<{
  'filter[status]': string;
}>;

export type getSaleInvoiceInput = {
  id: string;
};

export type getSaleInvoicePromoInput = getParamsInput;

export type getShowPendingPaymentInput = {
  saleInvoiceId: string;
};

export type getShowStatusInput = {
  saleInvoiceId: string;
};

export type SaleInvoiceBillInput = {
  productItemId?: string;
  qty?: number;
  simIccid?: string;
  activationAt: Date | null;
  promo?: {
    code?: string;
    type?: CheckoutPromoTypeEnum;
  };
};

export type SaleInvoiceBillCartInput = {
  promo?: {
    code?: string;
    type?: CheckoutPromoTypeEnum;
  };
};

export type SaleInvoiceCheckoutInput = {
  body: {
    productItemId?: string;
    qty?: number;
    simIccid?: string;
    activationAt: Date | null;
    promo?: {
      code?: string;
      type?: CheckoutPromoTypeEnum;
    };
  };
};

export type SaleInvoiceCheckoutCartInput = {
  body?: {
    promo?: {
      code?: string;
      type?: CheckoutPromoTypeEnum;
    };
  };
};

export type SaleInvoiceDownloadPdfInput = {
  saleInvoiceId: string;
};

export type SaleInvoicePreparePaymentInput = {
  saleInvoiceId: string;
  body?: {
    useKuroPoints?: boolean;
    paymentMethodId?: string;
    paymentIdentifierId?: string;
  };
};

export type SaleInvoiceSettlePaymentInput = {
  saleInvoiceId: string;
  body?: {
    useKuroPoints?: boolean;
    paymentMethodId?: string;
    paymentIdentifierId?: string;
  };
};

export type SaleInvoiceProcessPendingPaymentInput = {
  saleInvoiceId: string;
  body: {
    identifier: string;
  };
};
