import { useMutation } from '@tanstack/react-query';
import { meKey } from 'api-hooks/auth';
import { CommonUpdateInput } from 'api-hooks/common';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiMutationFunction } from 'common/api/fetch-types';
import { ApiResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';

import {
  AffiliateModel,
  AffiliateMutationInput,
  BindReferralInput,
  ReferralModel,
  ReferralMutationInput,
} from './model';
import { referralKey } from './query';

const REFERRAL_ENDPOINT = 'referrals';
const AFFILIATE_ENDPOINT = 'affiliates';

export const useCreateReferral: ApiMutationFunction<
  ReferralMutationInput,
  ApiResult<ReferralModel>
> = function (options) {
  return useMutation(
    async function (body) {
      return await MutationFetchFunction({
        url: REFERRAL_ENDPOINT,
        method: 'POST',
        body,
        classType: ReferralModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(referralKey.list());
      },
      ...options,
    },
  );
};

export const useUpdateReferral: ApiMutationFunction<
  CommonUpdateInput<ReferralMutationInput>,
  ApiResult<ReferralModel>
> = function (options) {
  return useMutation(
    async function (body) {
      return await MutationFetchFunction({
        url: `${REFERRAL_ENDPOINT}/${body.id}`,
        method: 'PUT',
        body: body.body,
        classType: ReferralModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(referralKey.list());
      },
      ...options,
    },
  );
};

/** For first time create */
export const useBindReferral: ApiMutationFunction<
  BindReferralInput,
  ApiResult<ReferralModel>
> = function (options) {
  return useMutation(
    async function (body) {
      return await MutationFetchFunction({
        url: `${REFERRAL_ENDPOINT}/bind-referral`,
        method: 'POST',
        body,
        classType: ReferralModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(meKey.getMeKey);
      },
      ...options,
    },
  );
};

export const useCreateAffiliate: ApiMutationFunction<
  AffiliateMutationInput,
  ApiResult<AffiliateModel>
> = function (options) {
  return useMutation(
    async function (body) {
      return await MutationFetchFunction({
        url: AFFILIATE_ENDPOINT,
        method: 'POST',
        body,
        classType: AffiliateModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(meKey.getMeKey);
      },
      ...options,
    },
  );
};

export const useUpdateAffiliate: ApiMutationFunction<
  AffiliateMutationInput,
  ApiResult<AffiliateModel>
> = function (options) {
  return useMutation(
    async function (body) {
      return await MutationFetchFunction({
        url: AFFILIATE_ENDPOINT,
        method: 'PUT',
        body,
        classType: AffiliateModel,
      });
    },
    {
      onSuccess(data: ApiResult<AffiliateModel>, variables, context) {
        queryClient.invalidateQueries(referralKey.affiliate());
      },
      ...options,
    },
  );
};
