import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getParamsInput, StaleTimes } from 'api-hooks/common';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import {
  ApiQueryFunction,
  InfiniteApiQueryFunction,
} from 'common/api/fetch-types';
import { ApiResult, ExtendedApiResult } from 'common/api/model';

import {
  AffiliateModel,
  ReferralModel,
  ReferralMonthlyPerformanceModel,
  ReferralPerformanceInput,
  RewardHistoriesInput,
  RewardHistoryModel,
} from './model';

const REFERRAL_ENDPOINT = 'referrals';
const AFFILIATE_ENDPOINT = 'affiliates';

export const referralKey = {
  listKey: 'getReferrals',
  historiesKey: 'getRewardHistories',
  performanceKey: 'getReferralPerformance',
  affiliateKey: 'getAffiliate',
  list() {
    return [referralKey.listKey];
  },
  histories(input?: getParamsInput) {
    return input
      ? [referralKey.historiesKey, input]
      : [referralKey.historiesKey];
  },
  performance(input?: ReferralPerformanceInput) {
    return input
      ? [referralKey.performanceKey, input.id, input.params]
      : [referralKey.performanceKey];
  },
  affiliate() {
    return [referralKey.affiliateKey];
  },
};

export const useGetReferrals: ApiQueryFunction<
  never,
  ApiResult<ReferralModel[]>
> = function (options) {
  return QueryTransformer(
    useQuery(
      referralKey.list(),
      () => {
        return QueryFetchFunction({
          url: REFERRAL_ENDPOINT,
        });
      },
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    ReferralModel,
  );
};

export const useGetRewardHistories: InfiniteApiQueryFunction<
  RewardHistoriesInput,
  ExtendedApiResult<RewardHistoryModel> & {
    totalReward: number;
  }
> = function (params, options) {
  const { id, ...restParams } = params;
  return QueryTransformer(
    useInfiniteQuery(
      referralKey.histories(params),
      async ({ pageParam = 1 }) => {
        const result = await QueryFetchFunction({
          url: `${REFERRAL_ENDPOINT}/${id}/reward-histories`,
          params: {
            ...restParams.params,
            page: pageParam,
          },
        });
        result.totalReward = result.total_reward;
        delete result.total_reward;
        return result;
      },
      options,
    ),
    RewardHistoryModel,
  );
};

export const useGetReferralPerformance: ApiQueryFunction<
  ReferralPerformanceInput,
  ApiResult<ReferralMonthlyPerformanceModel[]>
> = function (params, options) {
  return QueryTransformer(
    useQuery(
      referralKey.performance(params),
      () =>
        QueryFetchFunction({
          url: `${REFERRAL_ENDPOINT}/${params.id}/performance`,
          params: params.params,
        }),
      options,
    ),
    ReferralMonthlyPerformanceModel,
  );
};

export const useGetAffiliate: ApiQueryFunction<
  never,
  ApiResult<AffiliateModel>
> = function (options) {
  return QueryTransformer(
    useQuery(
      referralKey.affiliate(),
      () =>
        QueryFetchFunction({
          url: AFFILIATE_ENDPOINT,
        }),
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    AffiliateModel,
  );
};
